class OpalThemeJs{
	constructor(){
		$(document).ready(() => {
			new OpalPopupJs();
			new OpalStickyHeaderJs();
			new OpalBacktotopJs();
			new OpalCarouselJs();
			new OpalOffcanvasMenuJs();
			new OpalSortSelectJs();
			new OpalIsotopeJs();
			new OpalPrettyPhotoJs();
			new OpalFixVcJs();
			new OpalTooltipJs();
			new OpalCounterJs();
			new OpalDropdownJs();
			new OpalStickySidebarJs();
			new OpalFixedFooterJs();
		})

		$(window).load(function() {

		})
	}
}

new OpalThemeJs();