class OpalStickySidebarJs {
    constructor(){
        this.initSyickySidebar();
    }

    initSyickySidebar(){
        $('.sidebar-wrapper .sidebar').stick_in_parent({
            'parent'    : $('.row'),
            'offset_top': 10
        }).on('sticky_kit:bottom', function (e) {
            $(this).parent().css('position', 'static');
        }).on('sticky_kit:unbottom', function (e) {
            $(this).parent().css('position', 'relative');
        });
    }
}